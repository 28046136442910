<template>
  <div class="userone">
    <el-dialog :visible.sync="dialogVisible" width="75%" top="4vh">
    <div class="toptable">
      <div class="left">
        <span class="span1">用户信息</span>
        <table>
          <tr>
            <td>康康ID</td>
            <td>{{ Orddetails.id }}</td>
          </tr>
          <tr>
            <td>姓名</td>
            <td>
              {{ Orddetails.name }}
            </td>
          </tr>
          <tr>
            <td>性别</td>
            <td>
              {{ Orddetails.sex }}
            </td>
          </tr>
          <tr>
            <td>电话号码</td>
            <td>
              {{ Orddetails.phone }}
            </td>
          </tr>
          <tr>
            <td>机器编号</td>
            <td></td>
          </tr>
          <tr>
            <td>紧急联系人</td>
            <td>
              {{ Orddetails.emergencyContactTwo }}
            </td>
          </tr>
          <tr>
            <td>联系号码</td>
            <td>
              {{ Orddetails.emergencyContactTwoPhone }}
            </td>
          </tr>
          <tr>
            <td>登记位置</td>
            <td></td>
          </tr>
          <tr>
            <td>实际位置</td>
            <td>{{ Orddetails.address }}</td>
          </tr>
          <tr>
            <td>用户激活日期</td>
            <td>{{ Orddetails.joinDate }}</td>
          </tr>
          <tr>
            <td>历史充值总额</td>
            <td></td>
          </tr>
          <tr>
            <td>服务到期时间</td>
            <td>
              {{ Orddetails.leaveDate }}
            </td>
          </tr>
        </table>
      </div>
      <div class="leftcenter">
        <span class="span1">生活状况</span>
        <table>
          <tr>
            <td>居住情况</td>
            <td>{{ Orddetails1.live == "1" ? "独居" : "与子女住" }}</td>
          </tr>
          <tr>
            <td>智力状态</td>
            <td>{{ Orddetails1.intellect == "1" ? "正常" : "不正常" }}</td>
          </tr>
          <tr>
            <td>性格特征</td>
            <td>{{ Orddetails1.character == "1" ? "外向" : "内向" }}</td>
          </tr>
          <tr>
            <td>身体状态</td>
            <td>
              <div class="tdspan">
                <span
                  >语言：{{
                    Orddetails1.language == "1" ? "正常" : "不正常"
                  }}</span
                >
              </div>
              <div class="tdspan">
                <span
                  >听力：{{
                    Orddetails1.hearing == "1" ? "正常" : "不正常"
                  }}</span
                >
              </div>
              <div class="tdspan">
                <span>视力：{{ Orddetails1.vision == "1" ? "强" : "弱" }}</span>
              </div>
              <div class="tdspan">
                <span>
                  行动：{{ Orddetails1.action == "1" ? "正常" : "不正常" }}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>慢性病史</td>
            <td>
              {{ Orddetails1.illness }}
              <!-- <el-select v-model="value">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> -->
            </td>
          </tr>
        </table>
      </div>
      <div class="rightcenter">
        <span class="span1">健康数据</span>
        <table>
          <tr>
            <td>血压</td>
            <td>{{ Orddetails1.bloodPressure }}</td>
          </tr>
          <tr>
            <td>血糖</td>
            <td>
              {{ Orddetails1.bloodSugar }}
              <!-- <div class="tdspan">
                <span>空腹均值8.6</span>
              </div>
              <div class="tdspan">
                <span>餐二均值8.6</span>
              </div> -->
            </td>
          </tr>
          <tr>
            <td>心率</td>
            <td>{{ Orddetails1.heartRate }}</td>
          </tr>
          <tr>
            <td>体温</td>
            <td>{{ Orddetails1.temperature }}</td>
          </tr>
          <tr>
            <td>睡眠</td>
            <td>{{ Orddetails1.sleep }}</td>
          </tr>
          <tr>
            <td>用药安排</td>
            <td>
              <div class="tdspan">
                <span>早餐前 xxxx 1颗</span>
              </div>
              <div class="tdspan">
                <span>午餐前 复方氢氧化铝片 1片</span>
              </div>
              <div class="tdspan">
                <span>午餐后 消炎药 1颗</span>
              </div>
              <div class="tdspan">
                <span>晚餐前 xxxx 1颗</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="right">
        <span class="span1">应用数据</span>
        <table>
          <tr>
            <td>关爱次数</td>
            <td>56次</td>
          </tr>
          <tr>
            <td>求助次数</td>
            <td>10次</td>
          </tr>
          <tr>
            <td>打电话</td>
            <td>0次</td>
          </tr>
          <tr>
            <td>视频聊天</td>
            <td>0次</td>
          </tr>
          <tr>
            <td>相册欣赏</td>
            <td>0次</td>
          </tr>
          <tr>
            <td>收音机</td>
            <td>0次</td>
          </tr>
          <tr>
            <td>健康顾问</td>
            <td>无</td>
          </tr>
          <tr>
            <td>系统留言</td>
            <td>
              <span>留言数量：66条</span>
              <span>浏览数量：36条</span>
            </td>
          </tr>
          <tr>
            <td>生活服务消费</td>
            <td>658.3元</td>
          </tr>
        </table>
      </div>
    </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      options: [
        {
          value: "选项1",
          label: "高血压",
        },
        {
          value: "选项2",
          label: "糖尿病",
        },
        {
          value: "选项3",
          label: "心脏病",
        },
      ],
      value: "选项1",
      Orddetails: {},
      Orddetails1: {},
    };
  },
};
</script>

<style lang="less" scoped>
.userone {
  .toptable {
    height: 700px;
    display: flex;
    .left,
    .leftcenter,
    .right,
    .rightcenter {
      background: #ffffff;
      border-radius: 15px;
      flex: 1;
      margin: 0 10px;
      // 大字
      .span1 {
        display: block;
        font-size: 17px;
        width: auto;
        color: #909399;
        margin-right: 1%;
        font-weight: bold;
        text-align: center;
        margin-top: 6%;
      }
      table {
        width: 100%;
        margin-top: 20px;
        tr {
          margin-bottom: 18px;
          display: flex;
          td:first-child {
            color: #909399;
            font-weight: bolder;
            font-size: 15px;
            padding: 0 8px 0 20px;
            width: 103px;
          }
          td:nth-child(2) {
            font-size: 15px;
            color: #606266;
            padding-right: 15px;
            flex: 1;
          }
          td {
            .tdspan {
              line-height: 35px;
            }
            .el-select {
              width: 100%;
              .el-input__inner {
                border: 1px solid #ffffff;
                color: #c8c8c8;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
// 弹框
/deep/.el-dialog {
  border-radius: 18px;
  background-color: #c5c6c9 !important;
}
/deep/.el-dialog__header {
  border-radius: 18px 18px 0 0;
}
/deep/.el-dialog__close {
  color: transparent;
  background-image: url("../../assets/images/close.png");
  background-repeat: no-repeat;
  background-size: 14px;
}
/deep/.el-dialog__body {
  border-radius: 0 0 18px 18px;
  padding: 20px 20px 30px 20px;
}
</style>