<template>
  <div
    class="user"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="120px">
          <!-- <el-form-item label="用户区域">
            <el-select
              v-model="SerachInput.status"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in opArea"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="健康情况">
            <el-select
              v-model="SerachInput.groups"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in opHealth"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字查询">
            <el-select
              v-model="SerachInput.selectC"
              placeholder="请选择"
              clearable
              style="margin-right: 10px; width: 100px"
              @change="selectData(SerachInput.selectC)"
            >
              <el-option
                v-for="item in opSize"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="SerachInput.title"
              placeholder="请输入内容"
              style="width: 195px"
              clearable
            ></el-input>
          </el-form-item> -->
          <el-form-item label="使用者编号">
            <el-input
              v-model="SerachInput.userNumber"
              placeholder="请输入内容"
              style="width: 195px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="使用者昵称">
            <el-input
              v-model="SerachInput.userNames"
              placeholder="请输入内容"
              style="width: 195px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="家庭ID">
            <el-input
              v-model="SerachInput.familyID"
              placeholder="请输入内容"
              style="width: 195px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员电话">
            <el-input
              v-model="SerachInput.telPhone"
              placeholder="请输入内容"
              style="width: 195px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label-width="60px">
            <img
              src="../../assets/images/query.png"
              alt
              @click="searchNei"
              style="width: 49px"
            />
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="id"
            label="使用者编号"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="memberName"
            label="使用者昵称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="sex"
            label="性别"
            width="100"
          ></el-table-column>
          <el-table-column prop="equipmentNum" label="绑定设备数量" width="110">
            <template slot-scope="scope">
              <el-button
                size="small"
                v-if="scope.row.equipmentNum"
                @click="equnums(scope.$index, scope.row)"
              >
                <span>{{ scope.row.equipmentNum }}</span>
              </el-button>
              <span v-else>{{ scope.row.equipmentNum }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="familyId" label="家庭ID">
            <template slot-scope="scope">
              <el-button
                size="small"
                v-if="scope.row.familyId"
                @click="familyIdBtn(scope.$index, scope.row)"
              >
                <span>{{ scope.row.familyId }}</span>
              </el-button>
              <span v-else>{{ scope.row.familyId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="adminUserName"
            label="家庭管理员昵称"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="adminUserPhone"
            label="家庭管理员手机"
            width="120"
          ></el-table-column>
          <!-- <el-table-column prop="joinDate" label="最近在线"></el-table-column> -->
          <!-- <el-table-column
            prop="openRescueCn"
            label="紧急救援服务"
            width="120"
          ></el-table-column> -->
          <el-table-column prop="operation" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="details(scope.$index, scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 弹框 -->

        <!-- <el-dialog :visible.sync="dialogVisible" width="75%" top="4vh"> -->
        <xiangqing :oldUserId="oldUserId" ref="xiang"></xiangqing>
        <!-- </el-dialog> -->
        <div class="buttons">
          <!-- 分页 -->
          <!-- <el-pagination
          :page-size="pagesize"
          :pager-count="pagercount"
          layout="slot, pager"
          :total="100"
        >
          <img class="lefts" src="../../assets/images/leftpage.png" alt />
          <img class="rights" src="../../assets/images/rightpage.png" alt />
        </el-pagination> -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="pageCount"
            layout="total, prev, pager, next"
            :total="totalCount"
            @pagination="http"
          >
          </el-pagination>
          <!-- 按钮 -->
          <div class="annius">
            <el-button>数据分析</el-button>
            <el-button>数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
        title="绑定设备"
        :visible.sync="dialogVisibleEq"
        width="30%"
        :before-close="handleClose"
      >
        <el-form
          :inline="true"
          class="demo-form-inline"
          style="width: 90%; margin-left: 5%"
        >
          <el-form-item v-for="(item, index) in homeEquipment" :key="index">
            <span
              style="
                border: 1px solid rgb(204, 204, 204);
                text-align: center;
                line-height: 0px;
                display: inline-block;
                padding: 10px 12px;
                margin-right: 10px;
              "
              >{{ item.id }}</span
            ><span
              style="
                padding: 2px 10px;
                border: 1px solid rgb(204, 204, 204);
                margin-right: 10px;
              "
              >{{ item.productName }}</span
            >
            <span style="padding: 7px; border: 1px solid #ccc">{{
              item.imei
            }}</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleEq = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleEq = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
      title="家庭成员"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleCloses"
    >
      <el-form
        :inline="true"
        class="demo-form-inline"
        style="width: 94%; margin-left: 3%"
      >
        <el-form-item v-for="(item, index) in familyMember" :key="index">
          <div
            style="
              border: 1px solid rgb(204, 204, 204);
              margin: 6px;
              border-radius: 12px;
              text-align: center;
              line-height: 0px;
              display: inline-block;
              padding: 10px 12px;
            "
          >
            {{ item.id }}
          </div>
          <span
            style="padding: 7px; border: 1px solid #ccc; cursor: pointer"
            >{{ item.phone }}
            <!-- v-for="(name,index) in familyNmae" :key="index" -->
            <span>
              <span
                style="
                  padding: 2px 10px;
                  border: 1px solid rgb(204, 204, 204);
                  border-radius: 13px;
                  margin-left: 7px;
                "
                >{{ item.roleName }}</span
              >
            </span> </span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import xiangqing from "./userdetails";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      homeEquipment: {},
      SerachInput: {},
      opArea: [],
      opHealth: [],
      opSize: [],
      clientHeight: document.body.clientHeight,
      // 弹框
      // dialogVisible: false,
      dialogVisibleEq: false,
      // 分页
      currentPage1: 1,
      totalCount: 100,
      pageSize: 10,
      pageCount: 1,
      // 搜索
      value1: [],
      value: "",
      //   表格
      tableData: [],
      //用户id
      oldUserId: "",
      //用户详情
      Orddetails: {},
      dialogVisible: false,
      familyMember: [],
    };
  },
  components: {
    xiangqing,
  },
  created() {
    //查询老人列表
    this.http();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    equnums(index, row) {
      console.log("点击:", index, row);
      this.dialogVisibleEq = true;
      apiShout.getmemEquip({ memberId: row.id }).then((dd) => {
        console.log("设备维护：", dd.data);
        if (dd.data.code == 200) {
          this.homeEquipment = dd.data.result;
        } else {
          this.$message.error(dd.data.msg);
        }
      });
    },
    handleClose() {
      this.dialogVisibleEq = false;
    },
    handleSizeChange(size) {
      //console.log(`每页 ${size} 条`);
      this.pageSize = size;
      this.http();
    },
    handleCurrentChange(pageCount) {
      //console.log(`当前页: ${pageCount}`);
      this.pageCount = pageCount;
      this.http();
    },
    //详情
    details(index, row) {
      this.$refs.xiang.dialogVisible = true;
      this.oldUserId = row.id;
      apiShout.getMemberDetail({ id: row.id }).then((res) => {
        console.log("查询康康用户详情：", res.data);
        if (res.data.code == 200) {
          this.$refs.xiang.Orddetails = res.data.result.member;
          this.$refs.xiang.Orddetails1 = res.data.result.memberInfo;
        }
      });
    },
    //查询老人列表
    http() {
      apiShout
        .getMemberOld({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          id: this.SerachInput.userNumber,
          memberName: this.SerachInput.userNames,
          familyId: this.SerachInput.familyID,
          adminPhone: this.SerachInput.telPhone,
        })
        .then((res) => {
          console.log("老人列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else if (
            res.data.msg == "token不正确" ||
            res.data.msg == "token不存在"
          ) {
            this.$router.push("/");
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/member/exportMemberList",
          params: {
            useStatus: this.SerachInput.useStatus,
            imei: this.SerachInput.imei,
            type: this.SerachInput.userInfo,
            value: this.SerachInput.valueInfo,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "设备用户信息" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    searchNei() {
      this.http();
    },
    familyIdBtn(index,row){
      console.log(index,row,'查询家庭ID')
      this.dialogVisible = true;
      apiShout.getfamUserMid({ memberId: row.id }).then((res) => {
        console.log("家庭成员列表：", res.data);
        if (res.data.code === 200) {
          this.familyMember = res.data.result;
        }
      });
    },
    handleCloses(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
body {
  background: #ffffff;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 40px 20px 0px;
}
.el-input__inner {
  height: 33px;
  line-height: 33px;
}
.el-input__icon {
  line-height: 33px;
}
.user {
  .search {
    height: 65px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    .span1 {
      display: block;
      font-size: 14px;
      width: auto;
      color: #909399;
      margin-right: 1%;
      font-weight: bold;
    }
    // .el-select,
    // .el-cascader {
    //   margin-right: 20px;
    //   margin-left: 10px;
    // }
  }
  // table
  .tables {
    margin-right: 40px;
    margin-left: 25px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      td,
      th.is-leaf {
        border: none !important;
      }
      tr th {
        font-size: 14px;
        color: #909399;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 14px;
        color: #606266;
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
    // 按钮  分页
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .annius {
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // 分页
      .el-pagination {
        padding: 0;
        margin-top: 20px;
        .el-pager,
        .el-pager li {
          margin-top: 0px;
          color: #303133;
          min-width: 50px;
          font-size: 13px;
          font-weight: normal;
        }
        .el-pager li.active {
          color: #d78673;
        }
        .el-pager li:hover {
          color: #d78673;
        }
        img {
          display: inline-block;
        }
        .lefts {
          margin-right: 25px;
        }
        .rights {
          float: right;
          margin-left: 25px;
        }
      }
    }
  }
}
/deep/.el-form-item__label {
  color: #909399;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
// // 弹框
// /deep/.el-dialog {
//   border-radius: 18px;
//   background-color: #c5c6c9 !important;
// }
/deep/.el-dialog__header {
  border-radius: 18px 18px 0 0;
}
/deep/.el-dialog__close {
  color: transparent;
  background-image: url("../../assets/images/close.png");
  background-repeat: no-repeat;
  background-size: 14px;
}
/deep/ .el-dialog .el-dialog__headerbtn .el-dialog__close{
  color: rgba(255,255,255,0)!important;
}
/deep/.el-dialog__body {
  border-radius: 0 0 18px 18px;
  padding: 20px 20px 30px 20px;
}
/deep/.el-dialog {
  border-radius: 18px;
  background-color: #fff;
}
</style>